import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";
import { Helmet } from 'react-helmet';
import benchmark from '../assets/downloads/UX/MJN_CompetitiveBenchmark.pdf';

export default function UXAirline() {
  return (
    <React.Fragment>
        <Helmet>
        <title>UX Case Study Airline</title>
      </Helmet>
      <section className="section--project-hero ux">
        <div className="project--hero-content">
          <Navigation />

          <div className="row">
            <div className="large-7 columns small-10 medium-10 large-uncentered small-centered">
              <div className="project--intro">
                <h1>UX Research and Design of an airline website</h1>
                <p>
                  Design an improved flight booking experience for a startup
                  airline which is looking to create an online booking
                  experience that is fast, intuitive and easy.
                </p>
              </div>
            </div>
            <div className="large-5 columns medium-10 large-uncentered small-centered ">
              <img
                src="/assets/images/ux/mjn_hero.png"
                alt=""
                className="project--hero-image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="project--content">
        <div className="project--content-chapter first">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <p>
                The entire project took me just under 6 months to complete and
                made me go through the complete UX design process: research,
                analysis, concept and design. The endpoint was to design &amp; build
                a prototype and create a detailed set of accompanying wireframes
                ready to hand over to the developers.
              </p>

              <div className="row">
                <div className="large-4 columns">
                  <p>
                    <b>UX Techniques Used</b>
                  </p>
                  <ul>
                    <li>Competitive benchmarking</li>
                    <li>Affinity diagram</li>
                    <li>Customer Journey Map</li>
                    <li>User Flow Diagram</li>
                    <li>Wireframing &amp; Prototyping</li>
                    <li>Usability testing</li>
                  </ul>
                </div>
                <div className="large-4 columns">
                  <p>
                    <b>My Role</b>
                  </p>
                  <p>
                    Solo UX project where I performed all of the roles following
                    the full UX design process.
                  </p>
                </div>
                <div className="large-4 columns">
                  <p>
                    <b>Timeline</b>
                  </p>
                  <p>
                    August 2021 - February 2022 alongside the course modules.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="large-4 columns">
                  <p>
                    <b>Tools used</b>
                  </p>
                  <p>
                    <img
                      srcSet="/assets/images/ux/ux-tools.png, /assets/images/ux/ux-tools@2x.png 2x"
                      src="/assets/images/ux/ux-tools.png"
                      alt="UX Tools: Figma, Miro, Adobe  Illustrator, Adobe Photoshop, Adobe Indesign"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project--content-chapter alt">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>How are other websites solving the problem?</h2>
              <h3>Competitive Benchmarking</h3>
              <p>
                To learn more about best practices and conventions I did an
                in-depth analysis of 3 competitors and 1 aggregator: Brussels
                Airlines, KLM, Ryanair and Expedia. Going through the booking
                process on each website I quickly learned how these best-in
                className websites solved problems, what they did really well,
                what the conventions were and what could be improved.
              </p>
              <p>
                Along the way I took screenshots and colour coded pain points,
                positives and functionality working as expected.
              </p>
              <p><a href={benchmark} target="_blank" rel="noreferrer" className="follow">See full competitive benchmark</a></p>
            </div>
          </div>
          <div className="row">
            <div className="large-10 columns large-centered">
              <figure>
                <img
                  src="/assets/images/ux/mjn_benchmarking.png"
                  alt="Competitive benchmark of Ryanair"
                />
                <figcaption>Ryanair Homepage - airport selection</figcaption>
              </figure>
            </div>
          </div>
          <div className="row">
            <div className="large-8 medium-10 columns large-centered medium-centered">
              <div className="callout">
                <h4>Insights</h4>
                <p>
                  All of them used a somewhat similar way to book flights, but
                  some suffered from information overload and small print which
                  made it harder to flow through.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>How do users feel about the current solutions?</h2>
              <h3>Usability Testing</h3>
              <p>
                Next up was conducting my own usability test to gather richt
                insights from users. Since the airline I'm designing for doesn't
                have a website yet, I used it to conduct a comparative test.
                Along with a brief interview I had the user complete a task on 2
                different airline websites: Swiss Air and Iberia.
              </p>
              <p> The user was asked to:</p>
              <ul>
                <li>Book a flight from Brussels to Zürich/Barcelona</li>
                <li>Depart on a Saturday</li>
                <li>Last weekend of November</li>
                <li>7 nights</li>
                <li>2 adults</li>
              </ul>
              <p>
                I deliberately picked 2 sites I hadn't used in the comparative
                benchmark to get even more insights. The test showed me how a
                user navigates through the booking process and what the pain
                points &amp; positive experiences were. I also watched 2 additional
                usability tests provided by the UX Design Institute.
              </p>
              <figure>
                <img
                  src="/assets/images/ux/mjn_usabilitytesting.png"
                  alt="User testing the Iberia website during a usability test"
                />
                <figcaption>Usability test, testing Iberia</figcaption>
              </figure>
              <blockquote>
                “The flights aren't ordered like I would expect. It makes no
                sense. They order by departure time, but don't do it for flights
                with stops.”
              </blockquote>
              <div className="callout">
                <h4>Insights</h4>
                <ul>
                  <li>
                    Users prefer using a calendar rather than typing a date
                  </li>
                  <li>Users feel tricked when presented with the extras</li>
                  <li>
                    Selecting a fare isn't always intuitive and users can't find
                    how to proceed
                  </li>
                  <li>Users like smart defaults to save time</li>
                  <li>
                    Users don't like too many fare options, or options that
                    don't warrant a significant price difference
                  </li>
                </ul>
              </div>
              <h3>Note taking</h3>
              <p>
                After having watched/conducted the usability tests my
                note-taking skills were put to the test. My notes needed to be
                concise and detailed at the same time. I colour coded goals,
                behaviours, positives and pain points for easy reference later.
              </p>
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>What to do with all this unstructured data?</h2>
              <h3>Affinity Diagram</h3>
              <p>
                Now it was time to try and make sense of this large amount of
                raw qualitative data. Together with my partner I went through
                the research data from the usability tests and competitive
                benchmark. We put findings and observations on post-it notes in
                Miro and colour coded based on the project the data was gathered
                from. Next we tried to group them into logical groups trying to
                get as granular as possible. We discovered 12 groups, which each
                could be subdivided again in smaller ones.
              </p>
              <p><a href="https://miro.com/app/board/o9J_ltY9j34=/?invite_link_id=606449851201" className="follow" target="_blank"rel="noreferrer" >Explore the Affinity Diagram</a></p>
            </div>
          </div>
          <div className="row">
            <div className="large-10 columns large-centered">
              <figure>
                <img
                  src="/assets/images/ux/mjn_affinitiydiagram.png"
                  alt="Affinity diagram of research findings"
                />
                <figcaption>Affinity diagram after grouping</figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>What is the customer experience like?</h2>
              <h3>Customer Journey Map</h3>
              <p>
                Based on (most of) the categories from the affinity diagram I
                created the steps of the (overall) user journey. Next I added
                their goals, behaviour, context, pain points and positives and
                the corresponding data. After finishing I had a highly
                structured overview from the customer's perspective which
                highlighted the key aspects that should be fixed.
              </p>
              <p>
              <a href="https://www.figma.com/proto/6pY3pqpdgvDJYigT1e80cx/Customer-Journey-Map?page-id=88%3A215&node-id=88%3A215&viewport=274%2C48%2C0.21&scaling=scale-down-width" target="_blank" rel="noreferrer" className="follow">View Justomer Journey Map</a></p>
            </div>
            <div className="row">
              <div className="large-10 columns large-centered">
                <figure>
                  <img
                    src="/assets/images/ux/mjn_customerjourneymap.png"
                    alt="Customer journey map"
                  />
                  <figcaption>
                    Customer journey map showing key findings
                  </figcaption>
                </figure>
              </div>
            </div>
            <div className="row">
              <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
                <div className="callout">
                  <h4>Insights</h4>
                  <p>
                    Entering search data didn't really pose a problem. Users got
                    mostly confused when selecting flights, fares and seats.
                    When arriving at the add-ons and extras they felt annoyed
                    and afraid they would be tricked. These areas would need
                    most of my focus.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>How does the user flow through the structure?</h2>
              <h3>Flow Diagram</h3>
              <p>
                Using the findings from my research and analysis, I drew the
                high level flow for a single use case starting at the homepage
                and ending with the payment confirmation screen. Mapping the
                flow helped me understand all the steps the user needs to take
                to complete a booking in the most efficient way.
              </p>
              <p><a href="https://www.figma.com/proto/S2tEp70daSePa1IlwYYfU7/User-flow-diagram-Airline?page-id=166%3A1218&node-id=173%3A1827&viewport=274%2C48%2C0.29&scaling=scale-down-width" target="_blank" rel="noreferrer" className="follow">View Flow Diagram</a></p>
            </div>
          </div>
          <div className="row">
            <div className="large-10 columns large-centered">
              <figure>
                <img
                  src="/assets/images/ux/mjn_flowdiagram.png"
                  alt="Flow diagram showing user flow"
                />
                <figcaption>
                  Flow diagram showing how the user flows through booking a
                  flight
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Figuring out the interaction design</h2>
              <h3>Sketches / Low-Fidelity Prototype</h3>
            </div>
          </div>
          <div className="row">
            <div className="large-10 columns large-centered">
              <figure>
                <img
                  src="/assets/images/ux/mjn_lowfidelity.png"
                  alt="Sketches / low fidelity prototype"
                />
                <figcaption>
                  Sketches for the homepage, flight selection &amp; seat
                  selection
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <p>
                After defining the overall structure, it was time to draw the
                different screens. Using the flow diagram as a guide I drew all
                screens using pen and paper.
              </p>
              <p>
                The most important areas were the search box on the homepage,
                the flight selection and seat selection.
              </p>
              <p>
                Some screens took a few iterations to get a result that was
                clear and easy to use and followed the conventions I discovered
                during research.
              </p>
              <p>
                Buttons were scrapped or put in a different location, form
                fields were changed around and entire screens redrawn.
              </p>
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Designing the solution</h2>
              <h3>Medium-Fidelity Prototype</h3>
              <p>
                With all the screens finished on paper, it was time to start
                building the prototype in Figma. The prototype has all the
                necessary detail to test the high level flow, interactions,
                screen layout, copy and labelling.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="large-10 columns large-centered">
              <figure>
                <img
                  src="/assets/images/ux/mjn_highfidelity.png"
                  alt="Example of high-fidelity prototype"
                />
                <figcaption>Medium-Fidelity prototype</figcaption>
              </figure>
              <div className="text-centered">
                <a
                  href="https://www.figma.com/proto/UHqc4TUiwbFwvHqKVR1gAA/MJN-Air?page-id=0%3A1&node-id=94%3A2033&viewport=274%2C48%2C0.07&scaling=min-zoom&starting-point-node-id=94%3A2033"
                  target="_blank"
                  rel="noreferrer"
                  className="button hollow grey-500"
                >
                  Open in figma
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h3>Style Guide</h3>
              <p>
                While this wasn't necessary for turning in the project for
                grading, I couldn't take off my designer hat and created a small
                style guide to use in my medium-fidelity prototype.
              </p>

              <figure>
                <img
                  src="/assets/images/ux/mjn_styleguide.png"
                  alt="Style guide showing used colours and fonts"
                />
                <figcaption>MJN Air Style Guide</figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div className="project--content-chapter alt">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Communicating the solution with the developers</h2>
              <h3>Wireframes</h3>
              <p>
                The last step was to create a document with detailed design
                specifications to hand over to the developers. During this step
                I discovered some small issues and missing details from the
                prototype which I fixed and tested again. I tried to design it
                in a way that users only could proceed to the next step when all
                fields had been filled out or the necessery items had been
                selected to prevent error messages.
              </p>
              <p><a href="https://www.figma.com/proto/UHqc4TUiwbFwvHqKVR1gAA/MJN-Air?page-id=449%3A46406&node-id=487%3A35660&viewport=274%2C48%2C0.05&scaling=scale-down-width" target="_blank" rel="noreferrer" className="follow">View Annotated Wireframes</a></p>
            </div>
          </div>
          <div className="row">
            <div className="large-10 colums large-centered">
              <figure>
                <img
                  src="/assets/images/ux/mjn_wireframes.png"
                  alt="Wireframes with annotations"
                  data-zoomable
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Conclusion</h2>
              <h3>
                Or as Piet Huysentruyt would say, what did we learn today?
              </h3>
              <p>
                <b>You don't need to reinvent the wheel.</b>
              </p>
              <p>
                Conventions and best practices are there for a reason and users
                like things they know how to use.
              </p>
              <p>
                <b>
                  Follow the process. You can't design and build at the same
                  time.
                </b>
              </p>
              <p>
                One of the mistakes made working on previous projects is that me
                and my colleagues always built and designed at the same time.
                While it might seem to work faster, it is like going in blind
                and permanently trying to fix things “that don't seem to work”
                or “don't make sense”. When following the steps of the UX
                process, you gather all the insights beforehand, test them with
                users and come to a far better solution.
              </p>
              <p>
                <b>
                  You don't need prior knowledge of the service or product you
                  are designing for.
                </b>
              </p>
              <p>
                When starting out on the project it felt a bit daunting to
                design an airline booking process as I don't have a lot of
                experience booking flights. I shouldn't have worried because the
                research provides all the data and insights you need. Doing the
                competitive benchmark I got a good idea about how most airlines
                go about their booking process, the usability tests and customer
                journey{" "}
              </p>
              <h3>What would I do differently?</h3>
              <p>
                I still feel there are areas of improvement in my overall design
                which further testing with users could uncover.
              </p>
              <p>
                This being a solo project, my research data was limited and I
                sometimes got contradictory results. A bigger set of qualitative
                data might have helped work out these issues.
              </p>
              <p>
                There is currently no option to log in during the booking
                process. I didn't want to interrupt the user flow with a
                login/skip option, but it now feels like it is missing. More
                research and input from a developer team might bring better
                insights into this.
              </p>
              <p>
                I am however very satisfied with the result and how the final
                project turned out.
              </p>
              <h3>Thank you for flying MJN Air!</h3>
            </div>
          </div>
        </div>
      </section>
      <GoToTop />
      <Footer />
    </React.Fragment>
  );
}
