import React from "react";
import Navigation from "../components/Navigation";
import Project from "../components/Project";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";
import { Helmet } from 'react-helmet';

export default function Web() {
  return (
    
    <React.Fragment>
      <Helmet>
        <title>Web Projects</title>
      </Helmet>
      <section className="section--project-hero web">
        <div className="project--hero-content">
          <Navigation />

          <div className="row">
            <div className="large-12 columns medium-12 small-10 large-uncentered small-centered">
              <div className="project--intro">
                <h1>Web Projects</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Project
        projectName="Brainscape"
        projectDescription="UI design for brainscape.eu. The website offers information about the different Brainscape recycling services and is managed through a CMS."
        projectTasks="Design, UI, CSS/Foundation, HTML, Content management, CMS configuration"
        imageUrl="/assets/images/brainscape_640.png"
        imageUrlSmall="/assets/images/brainscape_320.png"
        altText="Brainscape website preview"
        bgColour="orange"
      />

      <Project
        imgRight="true"
        projectName="Laborama Expo"
        projectDescription="UI design for Laborama Expo, an annual event for the laboratory sector. The responsive website has an exhibitor-, brand- and product catalogue and is managed through a CMS."
        projectTasks="Design, UI, CSS/SASS/Foundation, HTML, CMS configuration"
        imageUrl="/assets/images/laborama_640.png"
        imageUrlSmall="/assets/images/laborama_320.png"
        altText="Laborama Expo website preview"
        bgColour="pink"
      />

      <Project
        projectName="Sylvie's Wine Auctions"
        projectDescription="Sylvie's wine auctions is an online wine auction where people can bid on wines in a bi-monthly auction. The site also has a blog, auction calendar and auction archive which is managed by Sylvie's through a CMS."
        projectTasks="Design, UI, CSS/SASS/Foundation, HTML, CMS configuration, Mailchimp integration/Templates"
        imageUrl="/assets/images/sylvies_640.png"
        imageUrlSmall="/assets/images/sylvies_320.png"
        altText="Sylvie's Wine Auctions website preview"
        bgColour="gold"
      />

      <Project
        imgRight="true"
        projectName="Ortemo"
        projectDescription="Responsive onepager for Ortemo, a think tank set up to address the issue of extramural orthopaedic care in the Peri &amp; Post Covid-19 era."
        projectTasks="Design, UI, CSS/SASS/Foundation, HTML, CMS configuration, corporate identity, Content management"
        imageUrl="/assets/images/ortemo_640.png"
        imageUrlSmall="/assets/images/ortemo_320.png"
        altText="Ortemo website preview"
        bgColour="bluegreen"
      />

      <Project
        projectName="Ankar Vastgoed"
        projectDescription="Responsive real estate website for Ankar Vastgoed as a showcase for their finished and future real estate projects."
        projectTasks="Design, UX, CSS/SASS/Foundation, HTML, CMS configuration, corporate identity"
        imageUrl="/assets/images/ankar_640.png"
        imageUrlSmall="/assets/images/ankar_320.png"
        altText="Ankar Vastgoed website preview"
        bgColour="blue"
      />

      <Project
        imgRight="true"
        projectName="Expodoc Floorplan"
        projectDescription="Responsive design for the Expodoc online floorplan. Linked to the Expodoc Floorplan Module and Exhibitor Manual. Exhibitors can book a booth of their choice and visitors can look up information about the different exhibitors. This application had to be able to work on mobile, tablet and desktop."
        projectTasks="Design, UI, CSS/SASS/Foundation, HTML, Floorplan Design"
        imageUrl="/assets/images/expodoc_320.png"
        imageUrlSmall="/assets/images/expodoc_320.png"
        altText="Expodoc Floorplan preview"
        bgColour="orange"
      />

      <Project
        projectName="Ahoy Floorplan"
        projectDescription="Online web application with an interactive floor plan for the events held at Ahoy Rotterdam. Depending on the halls used, a different floor plan is displayed. Visitors can look up information about the different exhibitors. I took care of the interface. It was important that the application worked on mobile, tablet and desktop."
        projectTasks="Design, UI, CSS/SASS/Foundation, HTML, Floorplan Design"
        imageUrl="/assets/images/ahoy_640.png"
        imageUrlSmall="/assets/images/ahoy_320.png"
        altText="Ahoy Floorplan preview"
        bgColour="grey"
      />

      <Project
        imgRight="true"
        projectName="T-F-A"
        projectDescription="B2B webshop selling furniture of select brands. I designed both website and webshop."
        projectTasks="Design, UX/UI, CSS/Foundation, HTML, Content management, Corporate Identity"
        imageUrl="/assets/images/tfa_640.png"
        imageUrlSmall="/assets/images/tfa_320.png"
        altText="T-F-A webshop preview"
        bgColour="lightgrey"
      />

      <Project
        projectName="Belgium Wine Watchers"
        projectDescription="Belgium Wine Watchers sells all its wines exclusively through its online wine shop. UI/UX design matching the existing corporate identity."
        projectTasks="Design, UI, CSS/Foundation, HTML"
        imageUrl="/assets/images/bww_640.png"
        imageUrlSmall="/assets/images/bww_320.png"
        altText="Belgium Wine Watchers webshop preview"
        bgColour="burgundy"
      />
      <GoToTop />
      <Footer />
    </React.Fragment>
  );
}
