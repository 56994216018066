import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";


export default function UIBank() {
  return (
    <React.Fragment>
        <Helmet>
        <title>UI Case Study Banking App</title>
      </Helmet>
      <section className="section--project-hero ui">
        <div className="project--hero-content">
          <Navigation />

          <div className="row">
            <div className="large-7 columns small-10 medium-10 large-uncentered small-centered">
              <div className="project--intro">
                <h1>UI Design of a banking application</h1>
                <p>
                Design a responsive banking application for a challenger brand looking to
                make waves in the financial world. They want an intuitive app that
                will help them stand out from the crowd.
                </p>
              </div>
            </div>
            <div className="large-5 columns medium-10 large-uncentered small-centered ">
              <img
                src="/assets/images/ui/ui_hero.png"
                alt=""
                className="project--hero-image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="project--content">
        <div className="project--content-chapter first">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <p>
              After successfully completing the <Link to="/projects/ux-case-study-airline">Diploma in UX Design</Link> with the UX Design Institute, I decided to brush up on and expand my UI knowledge with their certificate in UI Design. The course took me 3 months to complete and the course assignment consisted of creating mood boards, responsive screen designs for a banking platform and a reflection document.
              </p>
              
              <div className="row">
                <div className="large-4 columns">
                  <p>
                    <b>My Role</b>
                  </p>
                  <p>
                  Solo UI design project where I created mood boards, chose a colour palette, icons and fonts and designed user interfaces for three screens on desktop,
                  tablet and mobile.
                  </p>
                </div>
                <div className="large-4 columns">
                  <p>
                    <b>Timeline</b>
                  </p>
                  <p>
                    March 2022 - June 2022 alongside the course modules.
                  </p>
                </div>
             
                <div className="large-4 columns">
                  <p>
                    <b>Tools used</b>
                  </p>
                  <p>
                    <img
                      srcSet="/assets/images/ui/ui-tools.png, /assets/images/ui/ui-tools@2x.png 2x"
                      src="/assets/images/ui/ui-tools.png"
                      alt="UX Tools: Figma, Adobe  Illustrator"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="project--content-chapter alt">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>The brief</h2>
              <p>
              Create a a fresh new interface for a new responsive
              banking application. The client is a challenger brand looking to
              make waves in the financial world. They want an intuitive app that
              will help them stand out from the crowd.
              </p>
              <h3>Brand tone</h3>
              <p>
              <b>Playful: </b>Using our product should be a joy for users. We're not afraid
              to show some personality through colour, animation and shape, but
              never at the cost of being intuitive.
              </p>
              <p>
              <b>Clear: </b>We're dealing with people's money, so we need to present
              information in a logical and uncluttered way.
              </p>
              <p>
              <b>Trustworthy: </b>Users must feel they can trust our product.
              </p>
      
            </div>
          </div>
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Mood boards</h2>
              <h3>Creating a bank of reference</h3>
              <p>
              The first assignment consisted of creating a set of mood boards that could later act as <b>a source of inspiration</b> for the screen design of the banking application. 
              Alongside mood boards for Colour, Shape &amp; Typography there were also 3 mood boards representing the <b>brand values</b> Playful, Clear and  Trustworthy.
              </p>
              
            
            </div>
          </div>
          <div class="row">
            <div class="large-6 columns">
            <h4>Playful</h4>
            <figure class="moodboard">
                <img
                  src="/assets/images/ui/moodboard_playful.png"
                  alt="Mood board representing playful"
                />
               
              </figure>
            </div>
            <div class="large-6 columns">
            <h4>Clear</h4>
            <figure class="moodboard">
                <img
                  src="/assets/images/ui/moodboard_clear.png"
                  alt="Mood board representing clear"
                />
         
              </figure>
            </div>
          </div>

          <div class="row">
            <div class="large-6 columns">
            <h4>Trustworthy</h4>
            <figure class="moodboard">
                <img
                  src="/assets/images/ui/moodboard_trustworthy.png"
                  alt="Mood board representing trustworthy"
                />
               
              </figure>
            </div>
            <div class="large-6 columns">
            <h4>Colour</h4>
            <figure class="moodboard">
                <img
                  src="/assets/images/ui/moodboard_typography.png"
                  alt="Mood board with typography inspiration"
                />
         
              </figure>
            </div>
          </div>

          <div class="row">
            <div class="large-6 columns">
            <h4>Typography</h4>
            <figure class="moodboard">
                <img
                  src="/assets/images/ui/moodboard_colour.png"
                  alt="Mood board with colour inspiration"
                />
               
              </figure>
            </div>
            <div class="large-6 columns">
            <h4>Colour</h4>
            <figure class="moodboard">
                <img
                  src="/assets/images/ui/moodboard_shape.png"
                  alt="Mood board with inspiration for use of shapes"
                />
         
              </figure>
            </div>
          </div>

        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Low-fidelity wireframes</h2>
              <h3>Designs on paper</h3>
              <p>
                If the UX course tought me anything, it is that it's <b>best to start out with pen and paper</b>. 
                It's a quick way to get ideas down on paper and figuring out basic information architecture. The course provided some basic wireframes to start out with as well.
              </p>
              <figure>
                <img
                  src="/assets/images/ui/wireframes_mobile.png"
                  alt="Sketches / low fidelity prototype"
                />
                <figcaption>
                  Some sketches for the dashboard screens for mobile.
                </figcaption>
              </figure>
            </div>
          </div>
         
        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Design system</h2>
              <h3>Consistency is key</h3>
              <p>
              To maintain a consistent look throughout the product, a <b>design system</b> is essential. At the start of the design stage I picked fonts and a colour scheme that matched the brand values and I created a library in Figma for ease of use and consistency.
              </p>
              
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns large-centered">
            <figure>
                <img
                  src="/assets/images/ui/design_system.png"
                  alt="Preview of the design system showing typography, colour scheme and icons."
                  className=""
                />
                <figcaption>
                Preview of the design system showing typography, colour scheme and icons.
                </figcaption>
              </figure>
              </div>
              </div>


        </div>
        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Final screens</h2>
              <p>
              The final screens consisted of a dashboard, account and a spending screen. They had to work responsively on mobile, tablet and desktop.
              </p>
              
            </div>
            
          </div>
          <div className="row">
            <div className="large-12 columns large-centered">
            <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
            <h3>Mobile</h3>
            </div>
            </div>
              <figure>
                  <img
                  src="/assets/images/ui/screen_mobile_1.png"
                  alt="Tablet version Dashboard screen"
                  className="shadow threerow"
                />
                  <img
                  src="/assets/images/ui/screen_mobile_2.png"
                  alt="Tablet version Dashboard screen"
                  className="shadow centered threerow"
                 
                />
                  <img
                  src="/assets/images/ui/screen_mobile_3.png"
                  alt="Tablet version Dashboard screen"
                  className="shadow threerow"
                />
                <figcaption>
                  Mobile design screens: : Dashboard, Current Account, Spending
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns large-centered">
            <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
            <h3>Tablet</h3>
            </div>
            </div>
            <figure>
                <img
                  src="/assets/images/ui/screen_tablet_1.png"
                  alt="Tablet version Dashboard screen"
                  className="shadow"
                />
                <figcaption>
                  Tablet version Dashboard screen
                </figcaption>
              </figure>
              <figure>
                <img
                  src="/assets/images/ui/screen_tablet_2.png"
                  alt="Tablet version Current Account screen"
                  className="shadow"
                />
                <figcaption>
                  Tablet version Current Account screen
                </figcaption>
              </figure>
              <figure>
                <img
                  src="/assets/images/ui/screen_tablet_3.png"
                  alt="Tablet version Spending screen"
                  className="shadow"
                />
                <figcaption>
                  Tablet version Spending screen
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns large-centered">
            <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
            <h3>Desktop</h3>
            </div>
            </div>
            <figure>
                <img
                  src="/assets/images/ui/screen_desktop_1.png"
                  alt="Desktop version Dashboard screen"
                  className="shadow"
                />
                <figcaption>
                  Desktop version Dashboard screen
                </figcaption>
              </figure>
              <figure>
                <img
                  src="/assets/images/ui/screen_desktop_2.png"
                  alt="Desktop version Current Account screen"
                  className="shadow"
                />
                <figcaption>
                  Desktop version Current Account screen
                </figcaption>
              </figure>
              <figure>
                <img
                  src="/assets/images/ui/screen_desktop_3.png"
                  alt="Desktop version Spending screen"
                  className="shadow"
                />
                <figcaption>
                Desktop version Spending screen
                </figcaption>
              </figure>
            </div>
          </div>
        </div>



        <div className="project--content-chapter">
          <div className="row">
            <div className="large-8 medium-10 small-10 columns large-centered medium-centered small-centered">
              <h2>Reflection</h2>
              <p>
                <b>Which parts of my assignment am I particularly happy with?</b>
              </p>
              <p>
              When I started with this project I wanted to create a design with soft edges and organic
              shapes, but still a trustworthy and clear feel. I decided to use a blue leaning towards purple
              for its association with trustworthiness. The pinks add a more fun and not so serious note.
              The font I chose is a modern, versatile and easy to read sans serif and its rounded corners
              convey a little bit of extra playfulness. The design has a consistent look and scales nicely on
              the different screen sizes, making it look like a cohesive product.
              </p>

              <p>
                <b>Which parts of my assignment am I less happy with?</b>
              </p>
              <p>While I think the design both represents the brand principles “clear” and “trustworthy” quite
              well, I still feel it lacks a bit regarding “playful”. This could of course be solved within other
              screens by adding more playful visuals.</p>
              <p>
              The “Quick transfer” button on the “My Accounts” screen also keeps bugging me. Not
              knowing the exact purpose of this button made it hard to figure out what to do with it and it
              still feels a bit lost.</p>
              <p>
              I could easily show linked cards on the “Current Account” desktop design, but then had to
              find a way to represent this on both mobile and tablet. The icon works quite well on mobile,
              but I'm not convinced it is in the right place on tablet.</p>
              <p>
             During the feedback session after submission of my design for grading, it was pointed out that on the dashboard screen of the desktop version the first account is highlighted to indicate that its transactions are show on the right and it isn't entirely clear how that would work for the other accounts. I totally agree with that and I would need to rethink that part for a next iteration.</p>
              <p>
              However, these are all quite minor issues and overall I'm quite happy with the design.
              </p>
              <p>
                <b>What would I do differently if I had unlimited time to work on the assignment?</b>
              </p>
              <p>
              Since I am quite a perfectionist, I could tinker with this design until the last syllable of
              recorded time. There are always areas to improve or change.</p>
              <p>
              If I had unlimited time I would spend more time on the wireframe/black and white stage
              instead of diving in at the deep end. I went over this step a bit too quickly and ended up with
              a lot of different designs and ideas in this initial stage.</p>
              <p>
              Additionally, more competitor research could have helped to see what kind of solutions are
              out there and it could have given me valuable insights on how to solve problems I was
              struggling with later on.</p>
              <p>
              Lastly, I would also have liked to test it on some users to see if there are specific issues with
              the design that prevent them from completing their tasks.
              </p>
              <p>
                <b>Overall I really enjoyed the course and the challenges the design assignment brought with it along with the insights of the course modules.</b>
              </p>
            </div>
          </div>
        </div>
      </section>
      <GoToTop />
      <Footer />
    </React.Fragment>
  );
}
