import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="row">
        <div className="large-12 columns">
          &copy; Cowsonatrain {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}
