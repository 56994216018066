import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';
import pdf from '../assets/downloads/NatalieDeWinter_CV.pdf';
import usericon from '../assets/images/usericon.svg';

export default function About() {
  return (
    <React.Fragment>
       <Helmet>
        <title>About</title>
      </Helmet>
      <Navigation />
      <section className="section--about-hero">
        <div className="row">
          <div className="large-8 large-centered columns">
            <img src={usericon} className="usericon"  alt=""/>
            <h3>Nice to meet you!</h3>
            <p>
              I'm Natalie, a web designer, UX designer and graphic designer.
              That's lots of designer in one sentence.
            </p>
            <p>
              I am a creative colleague with 15 years of experience as a web
              designer and graphic designer.
            </p>
            <p>
              In recent years, I have worked on websites and applications for
              small to medium sized businesses, but also for larger companies
              such as the Wellness Kliniek and Ahoy Rotterdam.
            </p>
            <p>
              I like challenges. Whether it is work, hobbies or learning a new
              language. I recently got my Professional Diploma in UX Design from
              the UX Design Institute - Credit rated by The Glasgow Caledonian
              University and am currently following their Visual Design course.
            </p>
            <p>
              Outside work, I like to draw and explore nature with my camera.
            </p>
            <div className="buttons">
            <a
              href={pdf}
              target="_blank"
              rel="noreferrer"
              className="button hollow grey-500"
            >
              Download resume
            </a>
            <a
              href="mailto:hellowork@cowsonatrain.be"
              className="button hollow grey-500"
            >
              Get in touch
            </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}
