import { useEffect } from "react";
import { useLocation } from "react-router-dom";
 
//use this because on loading route, page stays at same position otherwise when clicking the buttons on the homepage
export default function GoToTop() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  
 return null;
//  return(
//    <div onClick={onTop}>linky</div>
//  );
}