import React from "react";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  return (
    <div className="nav">
      <div className="row">
        <div className="large-10 large-centered columns">
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Work
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  About
                </NavLink>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/natalie-de-winter"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linkedin
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
