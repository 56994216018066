import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import UXAirline from "./UXAirline";
import Web from "./Web";
import GraphicDesign from "./GraphicDesign";
import About from "./About";
import UIBank from "./UIBank";
// import NotFound from "./NotFound";

export default function RouterPage() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} title="Portfolio"/>
          <Route
            path="/projects/ux-case-study-airline"
            element={<UXAirline />}
            title="UX Case Study Airline"
          />
          <Route
            path="/projects/ui-case-study-banking-app"
            element={<UIBank />}
            title="UI Case Study Banking App"
          />
          <Route path="/projects/web-projects" element={<Web />} title="Web Projects" />
          <Route path="/projects/graphic-design" element={<GraphicDesign />} title="Graphic Design"/>
          <Route path="/about" element={<About />} title="About"/>
          {/* 404 rounte */}
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Router>
    </div>
  );
}
