//import logo from './logo.svg';
// import './App.css';

import RouterPage from './pages/RouterPage';

function App() {
  return (
    <div className="App">
      <RouterPage />
    </div>
  );
}

export default App;
