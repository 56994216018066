import React from "react";
export default function Project({
  projectName,
  projectDescription,
  projectTasks,
  imageUrl,
  imageUrlSmall,
  altText,
  bgColour,
  imgRight,
}) {
  if (!imgRight) {
    return (
      <div className="project--content-chapter">
        <div className="row">
          <div className="large-7 medium-7 large-uncentered medium-uncentered small-10 small-centered columns">
            <figure className={"project--image " + bgColour}>
              <img src={imageUrl} alt={altText}
               srcSet={`${imageUrlSmall} 320w, ${imageUrl} 640w`}
              sizes="(max-width: 40em) 320px, 
                      (max-width: 64em) 640px, 
                      (min-width: 64.063em) 640px, 640px"/>
            </figure>
          </div>
          <div className="large-5 medium-5 large-uncentered medium-uncentered small-10 small-centered columns">
            <h2>{projectName}</h2>
            <p>{projectDescription}</p>
            <p>{projectTasks}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="project--content-chapter reorder">
        <div className="row">
        <div className="large-5 medium-5 large-uncentered medium-uncentered small-10 small-centered columns">
            <h2>{projectName}</h2>
            <p>{projectDescription}</p>
            <p>{projectTasks}</p>
          </div>
          <div className="large-7 medium-7 large-uncentered medium-uncentered small-10 small-centered columns">
            <figure className={"project--image " + bgColour}>
              <img src={imageUrl} alt={altText}
               srcSet={`${imageUrlSmall} 320w, ${imageUrl} 640w`}
               sizes="(max-width: 40em) 320px, 
                       (max-width: 64em) 640px, 
                       (min-width: 64.063em) 640px, 640px" />
            </figure>
          </div>
        </div>
      </div>
    );
  }
}
